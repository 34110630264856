import { isRouteErrorResponse, Outlet, useRouteError } from "@remix-run/react";
import { useEffect } from "react";
import { useHydrated } from "remix-utils/use-hydrated";
import { MixPanel } from "~/utils/MixPanel";

export default function AuthLayout() {
  return (
    <div className="flex h-full">
      <Outlet />
    </div>
  );
}
export function ErrorBoundary() {
  const error = useRouteError();

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops</h1>
        <p>Status: {error.status}</p>
        <p>{error.data.message}</p>
      </div>
    );
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  let errorMessage = "Unknown error";
  // @ts-ignore
  if (error.message) {
    errorMessage = error.message;
  }

  return (
    <div className="grid h-screen place-items-center">
      <div className="flex flex-col items-center max-w-lg">
        <div className="alert alert-error text-center flex flex-col gap-4">
          <h1>Uh oh ...</h1>
          <p>Something went wrong.</p>
          {errorMessage}
          <p>Refresh the page and try again</p>
          <a
            onClick={() => window.location.reload()}
            className="btn w-full whitespace-nowrap flex flex-col px-4"
          >
            Refresh
          </a>
        </div>
      </div>
    </div>
  );
}
